
import { defineComponent, ref, reactive } from "vue";
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessage } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import SbomDataService from "@/services/SbomDataService";
import ResponseData from "@/types/ResponseData";
import { IsSelectArtifact } from "@/utils"
import { mapGetters} from 'vuex';
import { QuestionFilled } from '@element-plus/icons-vue'

export default defineComponent({
  name: "sbom-packages-list",
  components: {
    QuestionFilled
  },
  setup() {
    const conditionFormRef = ref<FormInstance>();
    const validateRules = reactive<FormRules>({
      binaryType: [
        {
          required: true,
          message: '请选择依赖类型',
          trigger: 'change',
        },
      ],
      type: [
        {
          required: true,
          message: '请选择包管理器类型',
          trigger: 'change',
        },
      ],
      name: [
        {
          required: true,
          message: '请输入Name',
          trigger: 'blur'
        },
      ],
    })

    return {
      conditionFormRef,
      validateRules,
    }
  },

  data() {
    return {
      pageData: [],
      pageNum: ref(1),
      pageSize: ref(10),
      totalElements: ref(0),
      Search: Search,

      conditionForm: reactive({
        binaryType: '',
        type: '',
        namespace: '',
        name: '',
        version: '',
        startVersion: '',
        endVersion: '',
      }),
      isOpenEuler: false,
      isSection: false,
      sectionDisabled: false
    };
  },
  computed:{
    ...mapGetters([
      'getProductName'
    ])
  },
  watch: {
    getProductName(newVal) {
      this.initTableData()
      this.formatIsOpeneuler()
    }
  },
  methods: {
    initTableData() {
      this.conditionForm = reactive({
        binaryType: '',
        type: '',
        namespace: '',
        name: '',
        version: '',
        startVersion: '',
        endVersion: '',
      })
      this.pageNum = 1
      this.pageData = []
    },
    indexCounter(index: number) {
      return index + (this.pageNum - 1) * this.pageSize + 1;
    },

    handlePageChange(val: number) {
      this.retrieveBinary(this.conditionFormRef);
    },

    handleSizeChange(val: number) {
      this.search();
    },
    
    search() {
      this.pageNum = 1
      this.retrieveBinary(this.conditionFormRef)
    },

    async retrieveBinary(formEl: FormInstance | undefined) {
      if (!formEl) return;
      if (!IsSelectArtifact(true)) {
        this.pageData = []
        return; 
      }

      await formEl.validate((valid, fields) => {
        if (valid) {
          let requestParam = new FormData()
          // requestParam.append('productName', (window as any).SBOM_PRODUCT_NAME);
          requestParam.append('productName', this.getProductName);
          requestParam.append('binaryType', this.conditionForm.binaryType);
          requestParam.append('type', this.conditionForm.type);
          requestParam.append('namespace', this.conditionForm.namespace);
          requestParam.append('name', this.conditionForm.name);
          requestParam.append('page', String(this.pageNum - 1))
          requestParam.append('size', String(this.pageSize))
          if(this.isSection) {
            if(!this.conditionForm.startVersion && !this.conditionForm.endVersion) {
              ElMessage({
                message: 'Version区间查询至少输入一个查询条件！',
                type: 'warning',
              })
              return
            }
            requestParam.append('startVersion', String(this.conditionForm.startVersion))
            requestParam.append('endVersion', String(this.conditionForm.endVersion))
          } else {
            requestParam.append('version', this.conditionForm.version);
          }
          SbomDataService.querySbomPackagesByBinary(requestParam)
            .then((response: ResponseData) => {
              this.pageData = response.data.content;
              this.totalElements = response.data.totalElements;
            })
            .catch((e: any) => {
              if (e.response && e.response.status == 500) {
                ElMessage({
                  message: e.response.data,
                  type: 'error'
                })
              }
              this.totalElements = 0
              this.pageData = []
            });
        }
      })
    },
    handleBinaryType(val) {
      if(val === 'RELATIONSHIP_MANAGER') {
        this.isSection = false
        this.sectionDisabled = true
      } else {
        this.sectionDisabled = false
      }
    },
    formatIsOpeneuler() {
      this.isOpenEuler = this.getProductName && this.getProductName.includes('openEuler')
    }
  },
  mounted() {
    this.retrieveBinary(undefined);
    this.formatIsOpeneuler()
  },
});
